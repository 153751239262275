import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const AccordionSection = styled.div`
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 10px;
  cursor: pointer;
`;

const AccordionContentWrapper = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  max-height: ${({ isOpen }) => (isOpen ? `${props => props.contentHeight}px` : '0')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`;

const AccordionContent = styled.div`
  padding: 15px;
  background-color: #f9f9f9;
  color: #333;
`;

const AccordionIcon = styled.div`
  font-size: 1.2em;
`;

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight); // Set the height of the content
    }
  };

  return (
    <AccordionSection>
      <AccordionHeader onClick={toggleAccordion}>
        <span>{title}</span>
        <AccordionIcon>{isOpen ? <FaChevronUp /> : <FaChevronDown />}</AccordionIcon>
      </AccordionHeader>
      <AccordionContentWrapper isOpen={isOpen} contentHeight={contentHeight}>
        <AccordionContent ref={contentRef}>
          {content}
        </AccordionContent>
      </AccordionContentWrapper>
    </AccordionSection>
  );
};

export default Accordion;
