import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  position: sticky;
  top: 0;
  z-index: 1000;
  background: rgba(42, 42, 42, 0.8);
  height: 85px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem calc((100vw - 1000px) / 2);
  backdrop-filter: blur(5px);
`;

export const NavLink = styled(Link)`
  color: #D0D0D0;
  font-size: 25pt;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #DDB250;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavLogo = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 24px;
  img {
    height: 80px;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a, button {
    margin-left: 24px;
  }

  @media screen and (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 85px;
    right: 0;
    background: rgba(42, 42, 42, 0.9);
    width: 100%; /* Stretch the menu across the full screen width */
    padding: 1rem;

    a {
      margin: 1rem 0;
    }
  }
`;
