import React from "react";
import Accordion from "./Accordion"; // Ensure you import the Accordion component
import "./card.css";

const Faq = () => {
  const faqs = [
    {
      question: "Wanneer en waar vindt het plaats?",
      answer: "Toernee General vindt plaats op 26 oktober van 21u30 tot 03u00 (wintertijd) in de sporthal van Erps-Kwerps (Oude Baan 14, 3071 Kortenberg). Aangezien de uurwisseling plaatsvindt, kunnen we een uurtje langer feesten!",
    },
    {
      question: "Hoe kan ik tickets kopen?",
      answer: (
        <>
          Tickets kunnen verkregen worden via onderstaande link. We werken hiervoor samen met NOVOTIX. Het is ook mogelijk een ticket te kopen aan de kassa ter plaatse!
          <br />
          <a
            className="card-v14__link"
            target="_blank"
            href="https://store.novotix.io/event/channel/cf577ff-87a0-85f2-a476-e8170f3efae100d538ed1e8a0a3d0ff1ad4ac7c6"
            rel="noopener noreferrer"
          >
            Tickets →
          </a>
        </>
      ),
    },
    {
      question: "Mag ik terug binnen na het verlaten van het evenement?",
      answer: "Neen, we werken met het “in=in out=out”-systeem. Je kan dus niet terug binnenkomen nadat je de evenementenzone hebt verlaten. We voorzien in onze evenementenzone voldoende plaats in open lucht zodat je een luchtje kan scheppen tussen het dansen door!",
    },
    {
      question: "Is er een veiligheidsperimeter rond het evenement?",
      answer: "Ja! Dit zijn regels die opgelegd worden van bovenaf om het evenement en de regio hierrond veilig te maken/houden voor iedereen. In een straal rond de sporthal van Erps-Kwerps zullen er verkeersborden staan die aangeven tot waar de veiligheidsperimeter geldt. Binnen deze zone mag je geen alcohol of drugs op zak hebben of ongepast gedrag vertonen. De politie-eenheden zullen hier strikt op toezien!",
    },
    {
      question: "Is er een minimumleeftijd?",
      answer: "Ja, de minimumleeftijd voor Toernee General is 14 jaar (geboren in 2010).",
    },
    {
      question: "Kan ik mijn ticket annuleren of doorverkopen?",
      answer: (
        <>
          Tickets zijn niet terugbetaalbaar, maar kunnen wel doorgegeven worden aan iemand anders. Tickets dienen steeds voor dezelfde prijs doorverkocht te worden, namelijk 10 euro per ticket. Let op, als je een ticket doorverkoopt, verandert het originele ticket en de QR-code niet. Dit betekent dat iedereen die de QR-code van het ticket heeft, toegang kan proberen te krijgen tot het evenement. Er is echter maar één toegang per ticket toegestaan: het eerste ticket dat gescand wordt aan de ingang, krijgt toegang. Als iemand anders al de QR-code heeft gebruikt, kun je zelf niet meer binnen. Wees dus voorzichtig als je een ticket van iemand anders koopt en zorg ervoor dat het ticket betrouwbaar is en nog niet is gebruikt.
        </>
      ),
    },
    {
      question: "Hoe kan ik Toernee General bereiken?",
      answer: (
        <>
          <p>
            Toernee General kan je op verschillende manieren bereiken, namelijk:
          </p>
          <p>
            ● Te voet of met de fiets: we sporen iedereen aan om te voet of met de fiets te komen. Er zal een ruime fietsenstalling voorzien worden, zonder toezicht van de organisatie.
          </p>
          <p>
            Jeugdraad Kortenberg is niet aansprakelijk voor diefstal of vernieling van persoonlijke bezittingen.
          </p>
          <p>BUS:</p>
          <ul>
            <li>Halte Erps-Kwerps Maalderij</li>
            <li>Halte Erps-Kwerps Erps Kerk</li>
          </ul>
          <p>TREIN:</p>
          <ul>
            <li>Station Kortenberg</li>
            <li>Station Erps-Kwerps</li>
          </ul>
          <p>● Auto: er is een aangrenzende parking met een beperkt aantal parkeerplaatsen. Er kan ook geparkeerd worden in de omliggende straten.</p>
          <p>STAY SAFE, RIDE SOBER! Drinken en rijden is NIET oké!</p>
        </>
      ),
    },
    {
      question: "Is er iets te eten?",
      answer: "Jazeker! Toernee General is niet Toernee General zonder Hamburgers Jeanine & kleinzoon Yarne!",
    },
    {
      question: "Wat zijn de betaalopties?",
      answer: (
        <>
          Aan de kassa en bonnenstand kan er met een betaalkaart, cash of Payconiq betaald worden. We raden aan om indien mogelijk met kaart te betalen.
          <br />
          Op Toernee General werken we met drankbonnen en drankkaarten, verkrijgbaar aan de bonnenstand. Er kan niet met cash betaald worden aan de toog.
          <br />
          Bij Hamburgers Jeanine & kleinzoon Yarne kan er niet betaald worden met drankkaarten en/of drankbonnen van Toernee General.
        </>
      ),
    },
    {
      question: "Kan ik mijn spullen ergens achterlaten?",
      answer: "Er zal een vestiaire voorzien worden waar spullen voor 1 euro achtergelaten kunnen worden voor de hele avond. Het is altijd mogelijk om je spullen even op te halen en terug te brengen. De vestiaire is onder toezicht van de vestiaire-medewerkers.",
    },
    {
      question: "Is er een afterparty?",
      answer: "Er wordt geen officiële afterparty georganiseerd, maar de fuif duurt een uur langer door de uurwisseling!",
    },
    {
      question: "Wat moet ik doen als ik iets verlies?",
      answer: "Gevonden voorwerpen zullen verzameld worden bij de vestiaire. Na het evenement kun je contact opnemen via jeugdraad.kortenberg@gmail.com of onze socials voor verloren voorwerpen. Er zal na het evenement een post op onze socials verschijnen met alle verloren voorwerpen.",
    },
    {
      question: "Is de fuif toegankelijk voor mindervaliden?",
      answer: "Toernee General is toegankelijk voor mindervaliden. Er zal echter geen aangepast podium worden opgesteld.",
    },
    {
      question: "Zijn er veiligheidsmaatregelen?",
      answer: "Ja, er zijn beveiligers aanwezig en we houden ons aan de geldende veiligheidsrichtlijnen. Bij de inkom zullen tassen en rugzakken gevraagd worden te controleren. Verdere informatie over verboden voorwerpen en gedragsregels kan je vinden bij de algemene voorwaarden. We vragen bezoekers respectvol om te gaan met elkaar en de organisatie en de regels te volgen. Ziet u iets verdacht of een situatie waar ingegrepen moet worden? Meldt het aan de organisatie!",
    },
    {
      question: "Help, ik (of iemand anders) heb medische hulp nodig!",
      answer: "Meldt het direct aan de organisatie! Er is een hulppost van het Rode Kruis Vlaanderen aanwezig die instaan voor medische noodgevallen. Deze zal duidelijk aangeduid worden.",
    },
    {
      question: "Mag ik foto- en videomateriaal maken tijdens de fuif?",
      answer: (
        <>
          Zeker en vast! Je mag foto’s en video’s maken, maar houd rekening met de privacy van andere bezoekers. Professionele camera’s zijn niet toegestaan zonder toestemming.
          <br />
          Deel je foto’s en video’s met hashtag #TG24 en tag @jeugdraadkortenberg.
        </>
      ),
    },
    {
      question: "Een vraag die niet in de FAQ staat?",
      answer: "stuur ons een mail: jeugdraad.kortenberg@gmail.com",
    },
    {
      question: "Is er een dresscode voor de fuif?",
      answer: "Neen, er is geen specifieke dresscode. Voel je vrij om te dragen waar je je goed in voelt. Toernee General heeft naast de indoor-zone ook een outdoor-zone.",
    },
  ];

  return (
    <div className="Faq-block">
      <div className="demo-card-v14-grid">
        {faqs.map((faq, index) => (
          <Accordion key={index} title={faq.question} content={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default Faq;
