import styled from 'styled-components';

export const CarouselContainer = styled.div`
  width: 100%; /* You can adjust this width as needed */
  margin: auto; /* Center the carousel */
  position: relative; /* Required for absolute positioning of buttons */
  padding-top: 20px;
`;

export const CarouselButton = styled.button`
  position: absolute;
  top: 0; /* Align the button to the top */
  height: 100%; /* Full height of the carousel */
  background-color: rgba(45, 45, 45, 0.5); /* Semi-transparent background */
  color: white; /* Text color */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor */
  z-index: 1000; /* Ensure it's above other elements */
  &:focus {
    outline: none; /* Remove focus outline */
  }
`;

export const NextButton = styled(CarouselButton)`
  right: 0; /* Align to the right */
`;

export const PrevButton = styled(CarouselButton)`
  left: 0; /* Align to the left */
`;

export const CarouselSlide = styled.div`
  display: flex;
  justify-content: center; /* Center the image within the slide */
  align-items: center;
`;

export const CarouselImage = styled.img`
  max-width: 100%; /* Make sure the image scales responsively */
  max-height: 200px; /* Set a max height for images */
  border-radius: 8px; /* Optional: Add rounded corners */
  display: block; /* Ensure block display to respect margins */
  margin: 0 auto; /* Center the image horizontally */
`;
