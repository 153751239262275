import styled from 'styled-components';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

export const FooterContainer = styled.footer`
  background-color: rgb(42, 42, 42);
  color: #808080;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 80%;
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FollowUs = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const SocialIcon = styled.a`
  margin-left: 10px;
  color: #fff;
  font-size: 1.5em;
`;

export const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
`;

export const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Copyright = styled.div`
  margin-top: 20px;
  font-size: 0.8em;
  text-align: center;
`;

export const FacebookIcon = styled(FaFacebook)`
  font-size: 1.5em;
`;

export const InstagramIcon = styled(FaInstagram)`
  font-size: 1.5em;
`;
