
import React from "react";
import './lineup.css';  // Import your CSS file

const LineUp = () => {
	return (
    <div class="containerImage">
      <div class="card">
        <img src="artists/5.png"></img>
      </div>
      <div class="card">
        <img src="artists/2.png"></img>
      </div>
      <div class="card">
        <img src="artists/6.png"></img>
      </div>
      <div class="card">
        <img src="artists/4.png"></img>
      </div>
      <div class="card">
        <img src="artists/1.png"></img>
      </div>
      <div class="card">
        <img src="artists/3.png"></img>
      </div>
      <div class="card">
        <img src="artists/7.png"></img>
      </div>
    </div>
  );
};

export default LineUp;
