import React, { useRef } from 'react';
import Slider from 'react-slick';
import {
  CarouselContainer,
  CarouselSlide,
  CarouselImage,
  PrevButton,
  NextButton,
} from './CarouselElements'; // Import styled components

const SponsorCarousel = ({ images }) => {
    const sliderRef = useRef(null); // Create a reference for the slider

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: true, // Enable center mode for better centering
        centerPadding: '60px', // Adjust padding as needed
        slidesToShow: 3, // Default number of slides to show
        arrows: false,
        responsive: [
          {
            breakpoint: 1024, // Adjust for larger screens
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 768, // Adjust for tablet screens
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: '30px', // Adjust padding for smaller screens
            },
          },
          {
            breakpoint: 480, // Adjust for mobile screens
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: '15px', // Adjust padding for smaller screens
            },
          },
        ],
      }

  return (
    <CarouselContainer>
      <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => (
          <CarouselSlide key={index}>
            <CarouselImage src={image} alt={`Slide ${index}`} />
          </CarouselSlide>
        ))}
      </Slider>
      {/* Custom navigation buttons */}
      <PrevButton onClick={() => sliderRef.current.slickPrev()}>❮</PrevButton>
      <NextButton onClick={() => sliderRef.current.slickNext()}>❯</NextButton>
    </CarouselContainer>
  );
};

export default SponsorCarousel;
