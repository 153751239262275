
import React from "react";
import './voorwaarden.css';  // Import your CSS file



const Voorwaarden = () => {
	return (
    <div className="vak">
      <h2>
        <strong>Algemene voorwaarden Toernee General 2024</strong>
      </h2>
      <h2>
        <strong>Jeugdraad</strong>
        <strong>Kortenberg</strong>
      </h2>
      <p>
        Het huidige reglement zal zoals wettelijk bepaald bij de ingang
        duidelijk zichtbaar worden gemaakt.
      </p>
      <p>Laatst gewijzigd op 05.09.2024.</p>
      <p>GEEN ETEN, DRANKEN, WAPENS of DRUGS TOEGESTAAN MEE TE NEMEN.</p>
      <p>
        <strong>ARTIKEL 1</strong>
      </p>
      <p>
        Personen die het evenemententerrein betreden dienen dit reglement te
        lezen en de bepalingen te aanvaarden en na te leven.
      </p>
      <p>
        <strong>ARTIKEL 2</strong>
      </p>
      <p>
        Elke persoon die zich aan de ingang van het evenemententerrein
        presenteert, dient in het bezit te zijn van ofwel een geldig
        toegangsbewijs ofwel een speciale toegangskaart uitgereikt door
        Jeugdraad Kortenberg. Een toegangsbewijs is slechts geldig voor 1
        toegang van 1 persoon en is alleen geldig vanaf 26 oktober 21:30 uur tot
        27 oktober 03:00 uur. Het vervalsen van het toegangsbewijs of enige
        andere speciale toegang tot de site zal zonder uitzondering worden
        vervolgd. De organisatie behoudt zich het recht voor om bezoekers de
        toegang tot het evenement te ontzeggen, ongeacht een geldig
        toegangsbewijs of speciale toegangsbewijs afgegeven door de organisator.
        Elke houder van een toegangsbewijs, of een speciale toelating afgegeven
        door Jeugdraad Kortenberg, is op eigen risico op het evenemententerrein
        en vrijwaart de organisator van elke aansprakelijkheid, inclusief
        diefstal en ongevallen.
      </p>
      <p>
        <strong>ARTIKEL 3</strong>
      </p>
      <p>
        Het toegangsbewijs of pas is niet inwisselbaar of terug betaalbaar door
        de organisatie.
      </p>
      <p>
        <strong>ARTIKEL 4</strong>
      </p>
      <p>
        Bij het betreden van het evenement dient het toegangsbewijs te worden
        getoond. Indien de controle van het ticket aangeeft dat het ticket niet
        geldig is, wordt de toegang tot het evenemententerrein geweigerd.
      </p>
      <p>
        <strong>ARTIKEL 5</strong>
      </p>
      <p>
        De toegang tot het evenemententerrein is verboden of geweigerd aan
        personen die:
      </p>
      <p>
        - duidelijk onder invloed zijn van alcohol, drugs of enig ander
        genotsmiddel;
      </p>
      <p>
        - eerder zijn uitgesloten van evenementen, opgelegd door de organisator
        of de administratieve of gerechtelijke autoriteiten;
      </p>
      <p>
        - door manifest gedrag te tonen de bedoeling te hebben de openbare orde
        te verstoren of zulks uit te lokken door aan te zetten tot
        geweldpleging, haat, woede enz.;
      </p>
      <p>
        - weerstand bieden aan de controle en de niet-systematische huiszoeking
        door de bewakers en veiligheidsdiensten van het evenement;
      </p>
      <p>
        - weigeren om voorwerpen aan te geven die door de veiligheidsdiensten
        van het evenement als gevaarlijk of verboden worden beschouwd (zie
        artikel 7);
      </p>
      <p>
        - op enigerlei wijze handelen in strijd met de bepalingen van
        &eacute;&eacute;n of meerdere artikelen van dit huishoudelijk reglement.
      </p>
      <p>
        <strong>ARTIKEL 6</strong>
      </p>
      <p>
        Iedere houder van een toegangsbewijs of een bijzondere toelating die het
        evenemententerrein wenst te betreden, dient zich te onderwerpen aan het
        toegangsbewijs of de bijzondere toelating. Het betreden van het
        evenemententerrein impliceert een mogelijke zoektocht. De beveiligers
        kunnen de houders van een toegangsbewijs of speciale toelating van
        hetzelfde geslacht vragen zich vrijwillig te onderwerpen aan een
        oppervlakkige inspectie van kleding en bagage, om voorwerpen op te
        sporen die, wanneer ze naar het evenemententerrein worden gebracht, het
        verloop van het evenement kunnen verstoren. het evenement, de veiligheid
        van de andere evenementgangers in gevaar brengen en de openbare orde
        verstoren. De bewakers kunnen verzoeken om afstand te doen van deze
        objecten.
      </p>
      <p>
        <strong>ARTIKEL 7</strong>
      </p>
      <p>
        Het is de houders van een toegangsbewijs of een speciale toelating
        verboden zich aan te melden bij de ingang van het evenemententerrein,
        alsmede de op het evenemententerrein aanwezige personen de volgende
        voorwerpen mee te brengen, te laten brengen of in bezit te hebben:
      </p>
      <p>
        - eigen drank of etenswaren, flesjes, glazen, drugs of genotsmiddelen en
        dergelijke;
      </p>
      <p>
        - elk apparaat (paraplu, zonnescherm, statief voor (film)camera,
        wandelstok) die door de veiligheidsdiensten als gevaarlijk wordt
        beschouwd voor de ander bezoekers van evenementen kunnen worden
        geweigerd;
      </p>
      <p>
        - projectielen of explosieven in vaste, vloeibare of gasvormige vorm;
      </p>
      <p>- ontvlambare producten of materialen, sprays;</p>
      <p>- pyrotechnische voorwerpen zoals Bengal Fire, enz.;</p>
      <p>
        - elk wapen of elk gevaarlijk, scherp of kneuzingsvoorwerp dat kan
        worden op zodanige wijze worden gebruikt (stokken, kettingen, steek- of
        steekwapens ed)
      </p>
      <p>
        - als het kan worden gebruikt als middel om de openbare orde te
        verstoren, de veiligheid van de menigte in gevaar te brengen en/of
        schade toe te brengen aan personen en goederen;
      </p>
      <p>- dieren zijn niet toegestaan op het evenemententerrein</p>
      <p>
        <strong>ARTIKEL 8</strong>
      </p>
      <p>
        Op het evenemententerrein is het ten strengste verboden voor houders van
        een toegangsbewijs of een speciale toelating om:
      </p>
      <p>
        - ontoegankelijke gedeelten te proberen te betreden of zich in
        ontoegankelijke gedeelten van het terrein te bevinden in overeenstemming
        met met het toegangsbewijs of de special toelating, zoals
        productieruimten, kunstenaarsloges, neutrale en vip-zones, perskamers,
        kantoren, backstage, enz.;
      </p>
      <p>
        - het beklimmen van tenten, constructies, hekken, afsluitingen,
        lichtmasten, tafels, banken of enige andere infrastructuur van het
        terrein;
      </p>
      <p>
        - de in-, uitgangen en evacuatieroutes te versperren en daar langer te
        blijven dan strikt nodig is voor het betreden of verlaten van het
        terrein;
      </p>
      <p>
        - zich vermommen of niet erkend worden, de openbare orde verstoren en de
        veiligheid van het publiek in gevaar brengen;
      </p>
      <p>
        - dranken, etenswaren of enig ander product verkopen of op de markt
        brengen zonder de uitdrukkelijke toestemming van de organisator ;
      </p>
      <p>
        - gooien of schieten met een voorwerp of vloeistof of enig ander product
        in losse of gasvormige vorm;
      </p>
      <p>- roken in ruimtes waar niet gerookt mag worden;</p>
      <p>- in het openbaar plassen;</p>
      <p>
        Overtreders kunnen door de organisator verwijderd worden van het
        evenemententerrein. De toegang tot het evenement wordt voor de rest van
        het evenement ontzegd, ook als zij in het bezit zijn van een nieuw
        geldig toegangsbewijs.
      </p>
      <p>
        <strong>ARTIKEL 9</strong>
      </p>
      <p>
        Teksten, symbolen, afbeeldingen, gebaren en obscene taal die aanzetten
        tot racisme, vreemdelingenhaat, provocatie en discriminatie zijn
        verboden.
      </p>
      <p>
        <strong>ARTIKEL 10</strong>
      </p>
      <p>
        Bezoekers dienen op het evenemententerrein te allen tijde de
        aanwijzingen van de organisator of zijn aangestelde of gemandateerde
        personen op te volgen. Bij niet nakomen volgt verwijdering van het
        evenemententerrein. Indien nodig kan de organisator een beroep doen op
        de politie.
      </p>
      <p>
        De houder van een toegangsbewijs verbindt zich ertoe zich te houden aan
        de instructies opgelegd door de organisator in het kader van de openbare
        rust en veiligheid tijdens het evenement.
      </p>
      <p>
        Bezoekers die om een van de hiervoor genoemde redenen van het
        evenemententerrein worden verwijderd, wordt de toegang tot het
        evenemententerrein voor de rest van het evenement ontzegd, ook als zij
        in het bezit zijn van een nieuw toegangsbewijs of speciale toegang.
      </p>
      <p>
        Indien de organisator iemand de toegang tot het evenemententerrein
        ontzegt, is de eerste gerechtigd om die persoon zijn toegangsbewijs te
        ontnemen en die persoon verdere toegang te ontzeggen om te voorkomen dat
        die persoon probeert het terrein te betreden.
      </p>
      <p>
        Indien de organisator die persoon van het terrein laat verwijderen, is
        de eerste niet verplicht het toegangsbewijs terug te betalen.
      </p>
      <p>
        <strong>ARTIKEL 11</strong>
      </p>
      <p>
        Om veiligheidsredenen behoudt de organisator zich het recht voor om:
      </p>
      <p>- het evenement te onderbreken of stop te zetten;</p>
      <p>
        - aan het einde van het evenement de bezoekers voorlopig op het terrein
        houden;
      </p>
      <p>- het terrein geheel of gedeeltelijk verlaten;</p>
      <p>
        - de toegang tot het terrein ontzeggen ongeacht het bezit van een geldig
        toegangsbewijs.
      </p>
      <p>
        De organisator kan genoodzaakt zijn het programma of de dienstregeling
        van het evenement te wijzigen. In dit geval blijft het toegangsbewijs
        geldig en is het niet inwisselbaar of terugbetaalbaar.
      </p>
      <p>
        <strong>ARTIKEL 12</strong>
      </p>
      <p>
        Om u zo goed mogelijk van dienst te zijn, is het noodzakelijk dat we
        contactgegevens en andere informatie over u bijhouden en gebruiken.
        Uiteraard willen wij ook uw privacy respecteren. We willen daarom
        volledig in lijn zijn met de nieuwe Europese privacywetten, de AVG
        (Algemene Verordening Gegevensbescherming) of AVG (Algemene Verordening
        Gegevensbescherming). Deze verordening is van toepassing vanaf 25 mei
        2018: Verordening (EU) 2016/679 van het Europees Parlement en de Raad
        van 27 april 2016 betreffende de bescherming van natuurlijke personen in
        verband met de verwerking van persoonsgegevens en betreffende het vrije
        verkeer van die gegevens.
      </p>
      <p>
        Iedereen die het evenemententerrein betreedt, gaat ermee akkoord dat hij
        of zij mag worden gefotografeerd en gefilmd en dat de beelden kunnen
        worden geregistreerd en opgeslagen. Deze foto's of beelden kunnen door
        de organisator worden gebruikt om het evenement te promoten. Het
        registreren en opslaan van deze beelden door de politie en/of
        organisator vindt tevens plaats met als doel de handhaving en naleving
        van de veiligheid op het evenemententerrein te waarborgen.
      </p>
      <p>
        De verwerking van de beelden heeft tot doel misdrijven en overtredingen
        van deze interne regelgeving te voorkomen en de daders op te sporen en
        te bestraffen door middel van identificatie.
      </p>
      <p>
        Foto's of filmpjes gemaakt door de bezoekers op de site mogen niet
        gebruikt worden voor commerci&euml;le of publieke doeleinden, tenzij met
        schriftelijke toestemming van de organisator.
      </p>
      <p>
        <strong>ARTIKEL 13</strong>
      </p>
      <p>
        13.1 Door middel van deze algemene voorwaarden stelt de organisator alle
        bezoekers op de hoogte van het feit dat de organisator gegevens
        verzamelt ter voorkoming of opsporing van onrechtmatig gedrag, ter
        bescherming of handhaving van wettelijke rechten en om
        veiligheidsredenen. Het gaat om de volgende persoonsgegevens: voornaam,
        achternaam, e-mailadres, geboortedatum en adres. Indien wettelijk
        toegestaan kan de organisator deze persoonsgegevens (voornaam,
        achternaam, e-mailadres, geboortedatum en adres) ook delen met
        overheidsinstanties of andere bevoegde instanties, ter bescherming of
        handhaving van zijn rechten of de rechten van derden, of bij het
        opsporen en voorkomen van fraude of andere misdrijven. Deze
        overheidsinstanties of bevoegde instanties kunnen de organisator
        negatief adviseren over de toegang van een tickethouder. In dat geval
        kan de organisator een kaarthouder de toegang tot het evenement weigeren
        of die persoon uit het evenement verwijderen. De tickethouder heeft geen
        recht op schadevergoeding.
      </p>
      <p>
        13.2 Om uw aankoop te kunnen verwerken, moet de organisator uw gegevens
        delen met derden. Door deze Algemene Voorwaarden te accepteren, geeft u
        uitdrukkelijk toestemming om uw gegevens te delen met onderstaande
        partijen voor de volgende doeleinden.
      </p>
      <p>13.2.1 De organisator kan uw gegevens delen met de volgende doelen:</p>
      <p>- Rapportage en statistieken over bezoekers van het evenement(en)</p>
      <p>- Ticket- en betalingsverwerking (Novotix)</p>
      <p>
        - Transactionele e-mails (zoals e-mails om de aankoop van uw ticket te
        bevestigen)
      </p>
      <p>
        13.2.2 De organisator kan uw gegevens delen met de volgende partijen:
      </p>
      <p>- Lokale en federale overheden</p>
      <p>- Mailingbedrijven</p>
      <p>- Operationeel personeel ter plaatse</p>
      <p>- Betalingsverwerkingsbedrijven</p>
      <p>
        - Veiligheids- en gezondheidsbedrijf en personeel (zoals beveiligings-
        of EHBOpersoneel)
      </p>
      <p>13.2.3 Ticketaanbieders</p>
      <p>
        Indien u gebruik wilt maken van uw recht om te worden vergeten, moet de
        organisator al uw persoonlijke gegevens wissen en kan hij u geen
        diensten meer aanbieden die nodig zijn om te voldoen aan uw bestelling
        en geeft u ook geen toegang tot het evenement. In dit geval wordt de
        bestelling niet terugbetaald.
      </p>
    </div>
  );
};

export default Voorwaarden;
