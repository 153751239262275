
import React from "react";
import './Home.css';  // Import your CSS file


const Home = () => {
	return (
    <div>
      <div className="App" style={{ backgroundImage: `url("/bg2.png")` }}>
        <main className="App-main">
          <img src="/logo.png" className="App-logo" alt="logo" />
          <p>Welkom!</p>
          <a
            className="App-link"
            href="https://store.novotix.io/event/channel/cf577ff-87a0-85f2-a476-e8170f3efae100d538ed1e8a0a3d0ff1ad4ac7c6"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="button-24" role="button">
              Tickets
            </button>
          </a>
        </main>
      </div>
      <div className="Midden">
        <h1>WE ARE BACK AND LOUDER THAN EVER!</h1>
        <h2>
          Toernee General is terug om Kortenberg op z’n kop te zetten als nooit
          tevoren!
        </h2>
        <h3>
          Op zaterdag 26 oktober gaat het los in de sporthal van Erps-Kwerps
          (Oudebaan 14).
        </h3>
        <h3>
          Verwacht een nacht vol ongeziene energie, met een line-up die iedereen
          omver zal blazen.
        </h3>
        <h3>
          Van lokaal talent tot internationale toppers – zelfs artiesten die op
          Tomorrowland hebben gestaan!
        </h3>
        <h3>
          Toernee General brengt het allemaal samen voor één onvergetelijke
          nacht.
        </h3>
        <h2>
          Dit wordt hét feest waar iedereen het over zal hebben. Mis het niet!
        </h2>
        <h3>
          Trommel je vrienden op en maak je klaar voor de wildste nacht van het
          jaar!
        </h3>
        <h1>Tickets zijn nu beschikbaar!</h1>
      </div>
    </div>
  );
};

export default Home;
