import React from 'react';
import {
  FooterContainer,
  FooterTop,
  FollowUs,
  SocialIcon,
  LinksSection,
  FooterLink,
  ContactSection,
  Copyright,
  FacebookIcon,
  InstagramIcon,
} from './FooterElements';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterTop>
        <FollowUs>
          <SocialIcon href="https://www.facebook.com/ToerneeGeneral" target="_blank" rel="noopener noreferrer">
            <FacebookIcon />
          </SocialIcon>
          <SocialIcon href="https://www.instagram.com/toerneegeneral" target="_blank" rel="noopener noreferrer">
            <InstagramIcon />
          </SocialIcon>
        </FollowUs>

        <LinksSection>
          <FooterLink href="/algemene-voorwaarden">Algemene Voorwaarden</FooterLink>
          <FooterLink href="/faqs">FAQ's</FooterLink>
        </LinksSection>

        <ContactSection>
          <p>Contact:<br/> <FooterLink href="mailto:Jeugdraad.Kortenberg@gmail.com">Jeugdraad.Kortenberg@gmail.com</FooterLink></p>
        </ContactSection>
      </FooterTop>

      <Copyright>
        <p>&copy; 2024 Jeugdraad Kortenberg | Website by Robbe Nevens</p>
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
