import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import SponsorCarousel from "./components/Sponsors/SponsorCarousel"; // Adjust the path as necessary
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import LineUp from "./pages/lineup";
import Voorwaarden from "./pages/voorwaarden";
import Faq from "./pages/faq";

function App() {
  const images = [
    "/sponsors/amplifon.jpg",
    "/sponsors/schoonaerde.png",
    "/sponsors/verhelst.jpg",
    "/sponsors/willems.png",
    "/sponsors/kbc.jpg",
    "/sponsors/broekveld.png",
    "/sponsors/moons.png",
    "/sponsors/osv.png",
    "/sponsors/patricia.jpg",
    "/sponsors/spruyt.png",
    "/sponsors/mutuel.png",
    "/sponsors/kurre.jpg",

    // Add more image URLs as needed
  ];
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/lineup" element={<LineUp />} />
        <Route path="/algemene-voorwaarden" element={<Voorwaarden />} />
        <Route path="/faqs" element={<Faq />} />
      </Routes>
      <SponsorCarousel images={images} />
      <Footer />
    </Router>
  );
}

export default App;
