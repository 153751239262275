// Filename - "./components/Navbar.js

import React, {useState} from "react";
import { Nav, NavLink, NavMenu, NavLogo, Bars } from "./NavbarElements";
import { Link } from "react-router-dom";

const Navbar = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

	return (
    <>
      <Nav>
        <NavLogo to="/">
          <img
            src="/logo.png"
            alt="Logo"
            style={{ height: "80px", marginRight: "auto" }}
          />
        </NavLogo>
        <Bars onClick={toggleMenu} />
        {/* We only use NavMenu and control its visibility with isOpen */}
        <NavMenu isOpen={isOpen}>
          <NavLink to="/faqs" activestyle="true" onClick={toggleMenu}>
            FAQ
          </NavLink>
          <NavLink to="/lineup" activestyle="true" onClick={toggleMenu}>
            Line-Up
          </NavLink>
          <a
            className="App-link"
            href="https://store.novotix.io/event/channel/cf577ff-87a0-85f2-a476-e8170f3efae100d538ed1e8a0a3d0ff1ad4ac7c6"
            target="_blank"
            rel="noopener noreferrer"
            onClick={toggleMenu}
          >
            <button className="button-24" role="button">
              Tickets
            </button>
          </a>
        </NavMenu>
      </Nav>
    </>
  );
};

export default Navbar;
